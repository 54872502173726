<template>
  <div>
    <div class="sheets">
      <h1>PrintOut</h1>
      <v-row>
        <v-col cols="12" md="2">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            :return-value.sync="startDate"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="startDate"
                prepend-icon="mdi-calendar"
                readonly
                v-on="on"
                hide-details
              />
            </template>
            <v-date-picker
              v-model="startDate"
              type="date"
              color="red"
              locale="ja-jp"
              no-title
              scrollable
            >
              <v-spacer />
              <v-btn text color="primary" @click="$refs.menu1.save(startDate)"
                >選択</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" md="2">
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="endDate"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="endDate"
                prepend-icon="mdi-calendar"
                readonly
                v-on="on"
                hide-details
              />
            </template>
            <v-date-picker
              v-model="endDate"
              type="date"
              color="red"
              locale="ja-jp"
              no-title
              scrollable
            >
              <v-spacer />
              <v-btn text color="primary" @click="$refs.menu2.save(endDate)"
                >選択</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="d-flex" cols="12" sm="2">
          <v-select
            v-model="company_select"
            :items="company"
            label="カンパニー"
            solo
          ></v-select>
        </v-col>
        <v-col class="d-flex" cols="12" sm="2">
          <v-select
            v-model="name_select"
            :items="name"
            label="名前"
            solo
          ></v-select>
        </v-col>

        <v-col cols="12" sm="1">
          <v-btn color="primary" @click="onFetchREport()">
            データ取得
          </v-btn>
        </v-col>
      </v-row>

      <!-- テーブル -->
      <v-data-table
        class="elevation-1"
        :headers="tableHeaders"
        :items="tableData"
        :footer-props="footerProps"
        :loading="loading"
        :sort-by="'date'"
        :sort-desc="true"
        :items-per-page="30"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr
              v-for="item in items"
              :key="item.report_id"
              @click="onClickEdit(item)"
            >
              <td align="right">
                {{ item.date }}
              </td>
              <td>
                {{ item.company }}
              </td>
              <td>
                {{ item.name }}
              </td>
              <td>
                <v-simple-checkbox
                  v-model="item.managercheck"
                  disabled
                ></v-simple-checkbox>
              </td>
              <td>
                <v-simple-checkbox
                  v-model="item.seniorcheck"
                  disabled
                ></v-simple-checkbox>
              </td>
              <td>
                <v-simple-checkbox
                  v-model="item.inputComplete"
                  disabled
                ></v-simple-checkbox>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>

      <v-row>
        <v-col cols="12" sm="1">
          <v-btn color="primary" @click="onClickPDF()">
            PDF出力
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-for="(c, key) in count" :key="key">
      <itemPDF ref="itemPDF" :c="c" />
    </div>
  </div>
</template>
<script>
import itemPDF from "../components/itemPDF.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import "../styles/printout.scss";

export default {
  name: "reportTable",
  components: {
    itemPDF,
  },
  data() {
    const today = new Date();
    const year = today.getFullYear();
    const month = ("0" + (today.getMonth() + 1)).slice(-2);
    var date = ("0" + today.getDate()).slice(-2) - 1;
    const dayOfWeek = today.getDay();
    const dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][dayOfWeek];
    if (dayOfWeekStr == "月") {
      date = date - 2;
    }
    return {
      tableData: [],
      menu1: false,
      menu2: false,
      /** ulidを複数取得 */
      ulid: [],
      count: [],
      startDate: `${year}-${month}-${date}`,
      endDate: `${year}-${month}-${date}`,
      company: "",
      name: "",
      name_select: "",
      company_select: "",
    };
  },
  computed: {
    ...mapState({
      /** 日報一覧データ */
      brTable: (state) => state.brTable,
      /** 指定の日報取得 */
      brData: (state) => state.brData,
      /** ローディング状態 */
      loading: (state) => state.loading.fetch,
      /** ロール取得 */
      role: (state) => state.role,
      /** 全日報データ取得 */
      reportAll: (state) => state.reportAll,
      /** company */
      company_set: (state) => state.company,
      /** salesName */
      name_set: (state) => state.salesName,
      /** get pdf data num */
      countPDF: (state) => state.countPDF
    }),
    ...mapGetters(["featchBrTable"]),
    /** テーブルのヘッダー設定 */
    tableHeaders() {
      return [
        { text: "日付", value: "date", align: "end" },
        { text: "カンパニー", value: "company", sortable: false },
        { text: "担当者名", value: "name", sortable: false },
        { text: "支配人チェック", value: "managercheck", sortable: false },
        { text: "上長チェック", value: "seniorcheck", sortable: false },
        { text: "提出済み", value: "inputComplete" },
      ];
    },
    /** テーブルのフッター設定 */
    footerProps() {
      return { itemsPerPageText: "", itemsPerPageOptions: [] };
    },
  },
  methods: {
    ...mapActions([
      /** 日報データ取得 */
      "fetchBrListData",
      "fetchSpecificBrData",
      "resetReport",
      "fetchAllBrData",
      "fetchReportPDF",
      "resetPDFBrData",
      /** 設定データ取得 */
      "fetchCompany",
      "fetchSalesName",
    ]),
    async loadSettingData() {
      await this.fetchCompany();
      await this.fetchSalesName();
    },
    async onFetchREport() {
      // debugger;
      var deltaDate = new Date(this.startDate);
      const endDate = new Date(this.endDate);
      // var delta = 0;
      this.ulid = [];
      // debugger;
      if (this.company_select === "" && this.name_select === "") {
        while (deltaDate <= endDate) {
          // debugger;
          for (var i = 1; i < this.company.length; i++) {
            if (this.company[i] === "") {
              continue;
            }
            for (var j = 1; j < this.name.length; j++) {
              if (this.name[j] === "") {
                continue;
              }
              this.ulid.push(
                this.geneUlid(deltaDate, this.company[i], this.name[j])
              );
            }
          }
          // debugger;
          // delta++;
          deltaDate.setDate(deltaDate.getDate() + 1);
        }
      } else if (this.company_select === "") {
        while (deltaDate <= endDate) {
          for (i = 1; i < this.company.length; i++) {
            if (this.company[i] === "") {
              continue;
            }
            this.ulid.push(
              this.geneUlid(deltaDate, this.company[i], this.name_select)
            );
          }
          // delta++;
          deltaDate.setDate(deltaDate.getDate() + 1);
        }
      } else if (this.name_select === "") {
        while (deltaDate <= endDate) {
          for (i = 1; i < this.name.length; i++) {
            if (this.name[i] === "") {
              continue;
            }
            this.ulid.push(
              this.geneUlid(deltaDate, this.company_select, this.name[i])
            );
          }
          // delta++;
          deltaDate.setDate(deltaDate.getDate() + 1);
        }
      } else if (this.company_select !== "" && this.name_select !== "") {
        while (deltaDate <= endDate) {
          this.ulid.push(
            this.geneUlid(deltaDate, this.company_select, this.name_select)
          );
          // delta++;
          deltaDate.setDate(deltaDate.getDate() + 1);
        }
      }
      console.log({ ulid: this.ulid });
      // debugger;
      this.count = [];
      // debugger;
      await this.resetPDFBrData();
      console.log({ lenght: this.ulid.length });
      for (i = 0; i <= this.ulid.length; i++) {
        // console.log({ ulid: this.ulid[i] })
        await this.resetReport();
        try {
          // debugger;
          await this.fetchSpecificBrData({ ulid: this.ulid[i] });
        } catch (e) {
          continue;
        }
      }
      this.count = this.countPDF
      console.log({countTrans: this.count})
      // debugger;
    },
    async onClickPDF() {
      window.print();
    },
    /** 日報一覧の最新を取得 */
    async updateTable() {
      const ulid = "";
      const list = this.brData[ulid];
      await this.fetchAllBrData();
      if (list) {
        this.tableData = list;
      } else {
        await this.fetchBrListData({ ulid });
        this.tableData = this.brTable;
      }
    },
    /** ulidを生成します */
    geneUlid(date, company, name) {
      function date2sec(date) {
        var d = new Date(date);
        return d.getTime();
      }

      // ulid準拠でTimeエンコード
      function encodeTime(time, len = 10) {
        const ENCODING = "0123456789ABCDEFGHJKMNPQRSTVWXYZ"; // Crockford's Base32
        const ENCODING_LEN = ENCODING.length;

        if (isNaN(time)) {
          throw new Error(time + " must be a number");
        }
        if (Number.isInteger(time) === false) {
          time = time - 0;
        }

        let mod;
        let str = "";
        for (; len > 0; len--) {
          mod = time % ENCODING_LEN;
          str = ENCODING.charAt(mod) + str;
          time = (time - mod) / ENCODING_LEN;
        }
        return str;
      }

      // 疑似ulid生成
      const sec = date2sec(date);
      const timeEncoded = encodeTime(sec);

      const hash1 = btoa(unescape(encodeURIComponent(company)));
      const hash2 = btoa(unescape(encodeURIComponent(name)));

      const ulid_ = timeEncoded + "_" + hash1 + "_" + hash2;
      const ulidFix = ulid_.replaceAll('/', '=-=');
      return ulidFix;
      // return timeEncoded + "_" + hash1 + "_" + hash2;
    },
  },
  async created() {
    await this.loadSettingData();
    this.company = ("," + this.company_set.toString()).split(",");
    this.name = ("," + this.name_set.toString()).split(",");
    this.updateTable();
  },
};
</script>
