<template>
  <div>
    <div v-for="(c, key) in count" :key="key">
      <div class="taskList">
        <taskList ref="taskList" :reportNum="reportNum" :c="c"></taskList>
      </div>
    </div>
  </div>
</template>

<script>
import taskList from "../components/taskListPDF.vue";
import { mapState } from "vuex";
import "../styles/printout.scss";

export default {
  name: "add-delete-task",
  components: {
    taskList,
  },
  computed: {
    ...mapState({
      /** PDF用データ */
      brDataPDF: (state) => state.brDataPDF,
    }),
    count: {
      get() {
        try {
          var count_ = [0];
          for (var i = 1; i < this.brDataPDF[this.reportNum].taskNo; i++) {
            // debugger;
            count_.push(i);
          }
          return count_;
        } catch (e) {
          return [0];
        }
      },
    },
  },
  props: ["reportNum"],
};
</script>
