<template>
  <div>
    <v-card>
      <v-card-title>
        <!-- 追加ボタン -->
        <v-col class="text-right" cols="12" v-if="role === 'sales'">
          <v-btn dark color="red">
            <v-icon @click="onClickAdd()">mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-card-title>
      <!-- テーブル -->
      <v-data-table
        class="elevation-1"
        :headers="tableHeaders"
        :items="tableData"
        :footer-props="footerProps"
        :loading="loading"
        :sort-by="'date'"
        :sort-desc="true"
        :items-per-page="30"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr
              v-for="item in items"
              :key="item.report_id"
              @click="onClickEdit(item)"
            >
              <td align="right">
                {{ item.date }}
              </td>
              <td>
                {{ item.company }}
              </td>
              <td>
                {{ item.name }}
              </td>
              <td>
                <v-simple-checkbox
                  v-model="item.managercheck"
                  disabled
                ></v-simple-checkbox>
              </td>
              <td>
                <v-simple-checkbox
                  v-model="item.seniorcheck"
                  disabled
                ></v-simple-checkbox>
              </td>
              <td>
                <v-simple-checkbox
                  v-model="item.inputComplete"
                  disabled
                ></v-simple-checkbox>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <v-row>
      <v-col cols="12" sm="10" />
      <v-col cols="12" sm="1">
        <v-btn color="primary">
          <JsonCSV :data="reportAll">csv出力</JsonCSV>
        </v-btn>
      </v-col>
    </v-row>
    <itemDialog ref="itemDialog" />
  </div>
</template>
<script>
import itemDialog from "../components/itemDialog.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import JsonCSV from "vue-json-csv";

export default {
  name: "reportTable",
  components: {
    itemDialog,
    JsonCSV,
  },
  data() {
    return {
      /** テーブルに表示させるデータ */
      tableData: [],
    };
  },
  computed: {
    ...mapState({
      /** 日報一覧データ */
      brTable: (state) => state.brTable,
      /** 指定の日報取得 */
      brData: (state) => state.brData,
      /** ローディング状態 */
      loading: (state) => state.loading.fetch,
      /** ロール取得 */
      role: (state) => state.role,
      /** 全日報データ取得 */
      reportAll: (state) => state.reportAll,
    }),
    ...mapGetters(["featchBrTable"]),
    /** テーブルのヘッダー設定 */
    tableHeaders() {
      return [
        { text: "日付", value: "date", align: "end" },
        { text: "カンパニー", value: "company", sortable: false },
        { text: "担当者名", value: "name", sortable: false },
        { text: "支配人チェック", value: "managercheck", sortable: false },
        { text: "上長チェック", value: "seniorcheck", sortable: false },
        { text: "提出済み", value: "inputComplete" },
      ];
    },

    /** テーブルのフッター設定 */
    footerProps() {
      return { itemsPerPageText: "", itemsPerPageOptions: [] };
    },
  },
  methods: {
    ...mapActions([
      /** 日報データ取得 */
      "fetchBrListData",
      "fetchSpecificBrData",
      "resetReport",
      "fetchAllBrData",
    ]),
    /** 日報一覧の最新を取得 */
    async updateTable() {
      const ulid = "";
      const list = this.brData[ulid];
      await this.fetchAllBrData();
      if (list) {
        this.tableData = list;
      } else {
        await this.fetchBrListData();
        this.tableData = this.brTable;
      }
    },
    /** 追加ボタンがクリックされたとき */
    async onClickAdd() {
      await this.resetReport();
      await this.$refs.itemDialog.open("add");
    },
    /** 編集ボタンがクリックされたとき */
    async onClickEdit(item) {
      const ulid = item.report_id;
      console.log({ ulid });
      await this.resetReport();
      await this.fetchSpecificBrData({ ulid });
      // console.log({reports: await this.fetchSpecificBrData({ulid})})
      this.$refs.itemDialog.open("edit");
    },
    async onClickCSV() {
      await this.fetchAllBrData();
      console.log({ AllReport: this.reportAll });
    },
  },
  created() {
    this.updateTable();
  },
};
</script>
