<template>
  
  <v-card flat>
    <v-card-text>
      <v-container>
        <v-text-field
          v-model="data.username"
          label="ユーザー名"
        ></v-text-field>
        <v-text-field
          v-model="data.password"
          :type="'password'"
          label="パスワード"
        ></v-text-field>
        <v-btn color='blue' text :disabled="loading || data.username.length === 0 || data.password.length === 0" @click="setJWTtoken">
          ログイン
        </v-btn>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  name: 'Login',
  data() {
    return {
      data: {
        username: "",
        password: ""
      }
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading.add
    })
  },
  methods: {
    ...mapActions([
      /** 認証トークンを取得 */
      "saveAuth",
    ]),
    async setJWTtoken() {
      await this.saveAuth({data: this.data}).then(() => {
        if (localStorage.JWT) {
          this.$router.push('/')
        }
      })
      .catch(() => window.alert("正しいIDとパスワードを入力してください。"));
    }
  }
}
</script>