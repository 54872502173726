import Vue from 'vue'
import Vuex from 'vuex'
import djangoApi from '../api/djangoApi.js'
import djangoAuth from '../api/djangoApi.js'

Vue.use(Vuex)

/** 
* State
* Vuexの状態
*/
const today = new Date()
const year = today.getFullYear()
const month = ('0' + (today.getMonth() + 1)).slice(-2)
const date = ('0' + (today.getDate())).slice(-2)
const state = {
  /** ロール */
  role: '',
  /** 追加・編集 */
  actionType: 'add',
  /** 日報 */
  reports: {
    report_id: '',
    date: `${year}/${month}/${date}`,
    company: '',
    name: '',
    target: '',
    bugetPQ: '',
    bugetMQ: '',
    resultPQ: '',
    resultMQ: '',
    managercheck: false,
    seniorcheck: false,
    managercomment: '',
    seniorcomment: '',
    taskNo: 1,
    taskStartTimes: ['00:00:00'],
    taskEndTimes: ['00:00:00'],
    taskTitles: [''],
    destinations: [''],
    taskDetails: [''],
    inputComplete: false
  },
  /** カンパニー */
  company: [],
  company_id: [],
  /** 営業担当者 */
  salesName: [],
  salesName_id: [],
  /** タスクの数 */
  count: [0],
  /** 日報データ */
  brData: {},
  /** PDF用日報データ */
  brDataPDF: [],
  countPDF: [],
  /** 日報テーブル */
  btTable: [],
  /** 全日報 */
  reportAll: [],
  /** 業務内容カテゴリ */
  taskCategory: [],
  taskCategory_id: [],
  /** ローディング状態 */
  loading: {
    fetch: false,
    add: false,
    update: false,
    delete: false
  },

  /** エラーメッセージ */
  errorMessage: '',

  /** 認証 */
  auth: '',
}

/**
 * Mutations
 * ActionsからStateを更新するときに呼ばれます
 */
const mutations = {
  /** ロールフラグ */
  saveRole (state, {role}) {
    state.role = role;
    localStorage.setItem('role', state.role);
  },
  /** access auth */
  saveAuth (state, {auth}) {
    state.auth = auth;
    localStorage.setItem('JWT', state.auth.access);
  },
  /** access auth */
  removeAuth (state) {
    state.auth = '';
    localStorage.removeItem('JWT');
  },
  /** 追加・編集フラグ */
  saveActionType (state, {actionType}) {
    state.actionType = actionType;
    localStorage.setItem('actionType', state.actionType);
  },
  /** 日報を保存します */
  saveReports (state, { reports }) {
    
    state.reports.report_id = reports.report_id
    state.reports.date = reports.date //.replace(/-/g, "/")
    state.reports.company = reports.company_select
    state.reports.name = reports.name_select
    state.reports.target = reports.target
    state.reports.bugetPQ = reports.bugetPQ
    state.reports.bugetMQ = reports.bugetMQ
    state.reports.resultPQ = reports.resultPQ
    state.reports.resultMQ = reports.resultMQ
    state.reports.managercheck = reports.managercheck
    state.reports.seniorcheck = reports.seniorcheck
    state.reports.managercomment = reports.managercomment
    state.reports.seniorcomment = reports.seniorcomment

    state.reports.taskNo = reports.taskNo
    state.reports.inputComplete = false

    localStorage.setItem('reports', JSON.stringify(state.reports))
  },
  /** 日報を完了フラグを付けて保存します */
  completeReports (state, { reports }) {
    // state.reports = { ...reports }
    state.reports.report_id = reports.report_id
    state.reports.date = reports.date //.replace(/-/g, "/")
    state.reports.company = reports.company_select
    state.reports.name = reports.name_select
    state.reports.target = reports.target
    state.reports.bugetPQ = reports.bugetPQ
    state.reports.bugetMQ = reports.bugetMQ
    state.reports.resultPQ = reports.resultPQ
    state.reports.resultMQ = reports.resultMQ
    state.reports.managercheck = reports.managercheck
    state.reports.seniorcheck = reports.seniorcheck
    state.reports.managercomment = reports.managercomment
    state.reports.seniorcomment = reports.seniorcomment

    state.reports.taskNo = reports.taskNo
    state.reports.inputComplete = true

    localStorage.setItem('reports', JSON.stringify(state.reports))
  },
  /** Reset Report */
  resetReport (state) {
    const today = new Date()
    const year = today.getFullYear()
    const month = ('0' + (today.getMonth() + 1)).slice(-2)
    const date = ('0' + (today.getDate())).slice(-2)
    const report_ini = {reports: {
      report_id: '',
      date: `${year}/${month}/${date}`,
      company: '',
      name: '',
      target: '',
      bugetPQ: '',
      bugetMQ: '',
      resultPQ: '',
      resultMQ: '',
      managercheck: false,
      seniorcheck: false,
      managercomment: '',
      seniorcomment: '',
      taskNo: 1,
      taskStartTimes: [],
      taskEndTimes: [],
      taskTitles: [],
      destinations: [],
      taskDetails: [],
      inputComplete: false
    }}
    state.count =  [0]
    state.reports = report_ini.reports
  },
  /** 日報を読み込みます */
  loadReports (state) {
    const reports = JSON.parse(localStorage.getItem('repors'))
    if (reports) {
      state.reports = Object.assign(state.reports, reports)
    }
  },

  /** タスクが追加されたら配列の末尾に空配列を追加 */
  taskAdd (state, {count}) {
    state.count = count;
    state.reports.taskNo = state.count.slice(-1)[0] + 1
    state.reports.taskStartTimes.push('00:00:00');
    state.reports.taskEndTimes.push('00:00:00');
    state.reports.taskTitles.push('');
    state.reports.destinations.push('');
    state.reports.taskDetails.push('');
    // console.log({reports: state.reports})
  },
  /** タスクが削除されたら配列の末尾を削除 */
  async taskDelete (state, {count}) {
    state.count = count;
    state.reports.taskNo = state.count.slice(-1)[0] + 1
    state.reports.taskStartTimes.pop();
    state.reports.taskEndTimes.pop();
    state.reports.taskTitles.pop();
    state.reports.destinations.pop();
    state.reports.taskDetails.pop();
    await djangoApi.deleteTask(state.reports.report_id + (state.reports.taskNo + 1))
  },
  /** タスクタイトルを保存 */
  editTaskTitle (state, {count, title}) {
    // console.log({count})
    // console.log({title})
    this.state.reports.taskTitles[count] = title;
  },
  /** 訪問先を保存 */
  editDestination (state, {count, destination}) {
    this.state.reports.destinations[count] = destination;
  },
  /** タスク詳細を保存 */
  editTaskDetail (state, {count, detail}) {
    this.state.reports.taskDetails[count] = detail;
  },
  /** タスク開始時間を保存 */
  editTaskStartTime (state, {count, startTime}) {
    var startTimePost = '00:00'
    startTime = startTime.replace(/:/g, '')
    if (startTime.length === 1 ){
      startTimePost = '0' + startTime.substr(0,1) + ':00:00'
    }
    else if (startTime.length === 2 ){
      startTimePost = startTime.substr(0,2) + ':00:00'
    }
    else if (startTime.length === 3 ){
        startTimePost = '0' + startTime.substr(0,1) + ':' + startTime.substr(1,2) + ':00'
    } else if (startTime.length > 3){
      startTimePost = startTime.substr(0,2) + ':' + startTime.substr(2,2) + ':00'
    }
    // console.log(startTimePost)
    this.state.reports.taskStartTimes[count] = startTimePost;
  },
  /** タスク終了時間を保存 */
  editTaskEndTime (state, {count, endTime}) {
    var endTimePost = '00:00'
    endTime = endTime.replace(/:/g, '')
    if (endTime.length === 1 ){
      endTimePost = '0' + endTime.substr(0,1) + ':00:00'
    }
    else if (endTime.length === 2 ){
      endTimePost = endTime.substr(0,2) + ':00:00'
    }
    else if (endTime.length === 3 ){
        endTimePost = '0' + endTime.substr(0,1) + ':' + endTime.substr(1,2) + ':00'
    } else if (endTime.length > 3 ){
      endTimePost = endTime.substr(0,2) + ':' + endTime.substr(2,2) + ':00'
    }
    this.state.reports.taskEndTimes[count] = endTimePost;
  },
  /** ローディング状態をセットします */
  setLoading (state, { type, v }) {
    state.loading[type] = v
  },

  /** エラーメッセージをセットします */
  setErrorMessage (state, { message }) {
    state.errorMessage = message
  },
  /** 日報データをセットします */
  setBrData (state, { list, task }) {
    state.brData = list
    
    state.reports.report_id = list.report_id
    state.reports.date = state.reports.date .replace(/-/g, "/")
    state.reports.company = list.company_select
    state.reports.name = list.name_select
    state.reports.target = list.target
    state.reports.bugetPQ = list.bugetPQ
    state.reports.bugetMQ = list.bugetMQ
    state.reports.resultPQ = list.resultPQ
    state.reports.resultMQ = list.resultMQ
    state.reports.managercheck = list.managercheck
    state.reports.seniorcheck = list.seniorcheck
    state.reports.managercomment = list.managercomment
    state.reports.seniorcomment = list.seniorcomment
    state.reports.taskNo = list.taskNo
    state.reports.inputComplete = list.inputComplete

    state.brData.taskTitles  = []
    state.brData.destinations  = []
    state.brData.taskDetails  = []
    state.brData.taskStartTimes  = []
    state.brData.taskEndTimes  = []

    for (var i = 0; i < task.length; i++) {
      state.reports.taskTitles.push(task[i].data.taskTitle)
      state.reports.destinations.push(task[i].data.destination)
      state.reports.taskDetails.push(task[i].data.taskDetail)
      state.reports.taskStartTimes.push(task[i].data.taskStartTime)
      state.reports.taskEndTimes.push(task[i].data.taskEndTime)
      // console.log('Title: ' + task[i].data.taskTitle)
      state.brData.taskTitles.push(task[i].data.taskTitle)
      state.brData.destinations.push(task[i].data.destinations)
      state.brData.taskDetails.push(task[i].data.taskDetail)
      state.brData.taskStartTimes.push(task[i].data.taskStartTime)
      state.brData.taskEndTimes.push(task[i].data.taskEndTime)
    }

    // console.log({state})
  },
  /** 日報一覧を取得する */
  setBrTable(state, { list }) {
    state.brTable = list;
  },
  /** 日報一覧代入する */
  getBrTable(state, { list }) {
    state.brTable = list;
  },
  /** 日報一覧にデータを追加する */
  addBrData (state, {report}) {
    const list = state.brTable
    var item = {report_id: report['report_id'], date: report['date'], company: report['company'], name: report['name'], managercheck: report['managercheck'], seniorcheck: report['seniorcheck'], inputComplete: report['inputComplete']}
    var push_flg = true
    if (list) {
      for (var i = 0; i < list.length; i++) {
        if (list[i]['report_id'] === report['report_id']) {
          push_flg = false
          break
        }
      }
      if (push_flg) {
        item['date'] = item['date'].replace(/\//g, '-')
        const start_pos = item['date'].indexOf("-")
        const end_pos = item['date'].lastIndexOf("-")
        // 月が一桁ものの頭に0をつける
        if (end_pos - start_pos === 2) {
          item['date'] = item['date'].replace(/-/, '-0')
        }
        // 日にちが一桁のものの頭に0をつける
        if (item['date'].length - end_pos === 1) {
          item['date'] = item['date'].replace(/(.*)-/, '$1-0')
        }
        list.push(item)
      } else {
        console.log('データが被っています。')
      }
    }
  },
  /** 日報一覧にデータを更新する */
  updateBrData (state, {report}) {
    const list = state.brTable
    var item = {report_id: report['report_id'], date: report['date'], company: report['company'], name: report['name'], managercheck: report['managercheck'], seniorcheck: report['seniorcheck'], inputComplete: report['inputComplete']}
    var updatePos = -1
    // console.log('Target :' + report['report_id'])
    if (list) {
      for (var i = 0; i < list.length; i++) {
        // console.log('ID: ' + list[i]['report_id'])
        if (list[i]['report_id'] === report['report_id']) {
          updatePos = i
          break
        }
      }
      if (updatePos === -1) {
        console.log('更新対象のデータが見つかりませんでした。')
      } else {
        item['date'] = item['date'].replace(/\//g, '-')
        const start_pos = item['date'].indexOf("-")
        const end_pos = item['date'].lastIndexOf("-")
        // 月が一桁ものの頭に0をつける
        if (end_pos - start_pos === 2) {
          item['date'] = item['date'].replace(/-/, '-0')
        }
        // 日にちが一桁のものの頭に0をつける
        if (item['date'].length - end_pos === 1) {
          item['date'] = item['date'].replace(/(.*)-/, '$1-0')
        }
        if (item.managercheck) {
          item.managercheck = true
        }
        else {
          item.managercheck=false
        }
        if (item.seniorcheck) {
          item.seniorcheck=true
        }
        else {
          item.seniorcheck=false
        }

        // 逐次表示のため一旦リストから削除後にpushする
        list.splice(updatePos, 1)
        list.push(item)
      }
    }
  },
  /** 全日報データ取得 */
  allBrData (state, {reportAll}) {
    state.reportAll = reportAll;
  },
  /** PDFリストに日報データ入れる */
  setPDFBrData (state, {brData}) {
    state.brDataPDF.push(brData)
    state.countPDF.push(state.countPDF.length)
  },
  /** PDFリストに日報データリセット */
  resetPDFBrData (state) {
    state.brDataPDF = []
    state.countPDF = []
  },
  /** カンパニーリスト設定 */
  setCompany (state, {id, company}) {
    state.company_id.push(id)
    state.company.push(company)
  },
  /** カンパニーリストをリセット */
  resetCompany (state) {
    state.company_id = [];
    state.company = [];
  },
  /** 営業担当者リスト設定 */
  setSalesName (state, {id, salesName}) {
    state.salesName_id.push(id)
    state.salesName.push(salesName)
  },
  /** 営業担当者リストをリセット */
  resetSalesName (state) {
    state.salesName_id = [];
    state.salesName = [];
  },
  /** 業務カテゴリリストをリセット */
  setTaskCategory (state, {id, taskCategory}) {
    state.taskCategory_id.push(id);
    state.taskCategory.push(taskCategory);
  },
  /** 業務カテゴリリストをリセット */
  resetTaskCategory (state) {
    state.taskCategory_id = [];
    state.taskCategory = [];
  },
}

/**
 * Actions
 * 画面から呼ばれ、Mutationをコミットします
 */
const actions = {
  /** 日報データを取得します */
  async fetchBrListData ({ commit }){ //, { ulid }) {
    const type = 'fetch'
    commit('setLoading', { type, v: true })
    try {
      const res = await djangoApi.reportList()
      for (var i = 0; i < res.data.length; i++) {
        res.data[i]['date'] = res.data[i]['date'].replace(/\//g, '-')
        const start_pos = res.data[i]['date'].indexOf("-")
        const end_pos = res.data[i]['date'].lastIndexOf("-")
        // 月が一桁ものの頭に0をつける
        if (end_pos - start_pos === 2) {
          res.data[i]['date'] = res.data[i]['date'].replace(/-/, '-0')
        }
        // 日にちが一桁のものの頭に0をつける
        if (res.data[i]['date'].length - end_pos === 1) {
          res.data[i]['date'] = res.data[i]['date'].replace(/(.*)-/, '$1-0')
        }
      }
      commit('setBrTable', { list: res.data })
    } catch (e) {
      commit('setErrorMessage', { message: e })
      commit('setBrTable', { list: [] })
    } finally {
      commit('setLoading', { type, v: false })
    }
  },
  /**  指定日報データを取得します */
  async fetchSpecificBrData ({ commit }, { ulid }) {
    // debugger;
    const type = 'fetch'
    commit('setLoading', { type, v: true })
    try {

      var res = await djangoApi.report(ulid)
      // debugger;
      res.data['date'] = res.data['date'].replace(/\//g, '-')
      const start_pos = res.data['date'].indexOf("-")
      const end_pos = res.data['date'].lastIndexOf("-")
      // 月が一桁ものの頭に0をつける
      if (end_pos - start_pos === 2) {
        res.data['date'] = res.data['date'].replace(/-/, '-0')
      }
      // 日にちが一桁のものの頭に0をつける
      if (res.data['date'].length - end_pos === 1) {
        res.data['date'] = res.data['date'].replace(/(.*)-/, '$1-0')
      }

      // タスクを配列で取得する
      var task = []
      for (var i = 1; i <= res.data['taskNo']; i++) {
        task.push(await djangoApi.task(ulid + i))
      }

      // console.log({reports: res.data})
      commit('setBrData', { list: res.data, task: task })
      if (res.data['managercheck'] === true && res.data['seniorcheck'] === true && res.data['inputComplete'] === true) {
        // debugger;
        commit('setPDFBrData', {brData: res.data})
      }
    } catch (e) {
      commit('setErrorMessage', { message: "指定のデータが見つかりませんでした" })
      commit('setBrData', { list: [] })
    } finally {
      commit('setLoading', { type, v: false })
    }
    
  },
  /**  全日報データを取得します */
  async fetchAllBrData ({ commit }) {
    const type = 'fetch'
    commit('setLoading', { type, v: true })
    try {
      var reportAll = await djangoApi.reportAll()
      var taskAll = await djangoApi.taskAll()
      var reportData = []
      for (var i = 0; i < reportAll.data.length; i++) {
        // reportData[i] = reportAll.data[i]
        reportData.push(reportAll.data[i])
        var cnt = 0
        for (var j = 0; j < taskAll.data.length; j++) {
          if (taskAll.data[j]['report_id'] === reportAll.data[i]['report_id']) {
            reportData[i]["taskStartTime"+cnt] = taskAll.data[j]['taskStartTime']
            reportData[i]["taskEndTime"+cnt] = taskAll.data[j]['taskEndTime']
            reportData[i]["taskNo"+cnt] = taskAll.data[j]['taskNo']
            reportData[i]["taskTitle"+cnt] = taskAll.data[j]['taskTitle']
            reportData[i]["destinations"+cnt] = taskAll.data[j]['destinations']
            reportData[i]["taskDetail"+cnt] = taskAll.data[j]['taskDetail']
            cnt++;
          }
        }
        // console.log({reportData})
      }
      // console.log({reportData})
      commit('allBrData', {reportAll: reportData})
    } catch (e) {
      commit('setErrorMessage', { message: e })
    } finally {
      commit('setLoading', { type, v: false })
    }
    
  },
  /**  業務カテゴリデータを取得します */
  async fetchTaskCategory ({ commit }) {
    const type = 'fetch'
    commit('setLoading', { type, v: true })
    try {
      commit('resetTaskCategory')
      var taskCategory = await djangoApi.taskCategory()
      for (var i = 0; i < taskCategory.data.length; i++) {
        commit('setTaskCategory', {id: taskCategory.data[i]['auto_increment_id'], taskCategory: taskCategory.data[i]['task_category']})
      }
    } catch (e) {
      commit('setErrorMessage', { message: e })
    } finally {
      commit('setLoading', { type, v: false })
    }
  },
  /** カンパニーデータを取得します */
  async fetchCompany ({ commit }) {
    const type = 'fetch'
    commit('setLoading', { type, v: true })
    try {
      commit('resetCompany')
      var company = await djangoApi.company()
      // debugger;
      for (var i = 0; i < company.data.length; i++) {
        commit('setCompany', {id: company.data[i]['auto_increment_id'], company: company.data[i]['name']})
      }
    } catch (e) {
      commit('setErrorMessage', { message: e })
    } finally {
      commit('setLoading', { type, v: false })
    }
  },
  /** 営業担当者名データを取得します */
  async fetchSalesName ({ commit }) {
    const type = 'fetch'
    commit('setLoading', { type, v: true })
    try {
      commit('resetSalesName')
      var salesName = await djangoApi.salesName()
      for (var i = 0; i < salesName.data.length; i++) {
        commit('setSalesName', {id: salesName.data[i]['auto_increment_id'], salesName: salesName.data[i]['name']})
      }
    } catch (e) {
      commit('setErrorMessage', { message: e })
    } finally {
      commit('setLoading', { type, v: false })
    }
  },
  /** 日報追加 */
  async addBrData ({ commit }, { reports }) {
    const type = 'add'
    // console.log({reports})
    var item = {
      "report_id": reports['report_id'],
      "date": reports['date'],
      "company": reports['company'],
      "name": reports['name'],
      "target": reports['target'],
      "bugetPQ": reports['bugetPQ'],
      "bugetMQ": reports['bugetMQ'],
      "resultPQ": reports['resultPQ'],
      "resultMQ": reports['resultMQ'],
      "managercheck": reports['managercheck'],
      "seniorcheck": reports['seniorcheck'],
      "managercomment": reports['managercomment'],
      "seniorcomment": reports['seniorcomment'],
      "taskNo": reports['taskNo'],
      "inputComplete": reports['inputComplete']
    }
    try {
      await djangoApi.postReport(JSON.stringify(item))
      
      for (var i = 0; i < reports['taskNo']; i++) {
        // console.log({reports: reports['taskTitles'][i]})
        item = {
          "task_id": reports['report_id'] + (i+1),
          "report_id": reports['report_id'],
          "taskNo": i + 1,
          "taskTitle": reports['taskTitles'][i],
          "destinations": reports['destinations'][i],
          "taskDetail": reports['taskDetails'][i],
          "taskStartTime": reports['taskStartTimes'][i],
          "taskEndTime": reports['taskEndTimes'][i]
        }
        // console.log({item})
        await djangoApi.postTask(JSON.stringify(item))
      }
      commit('addBrData', { report: reports })
    } catch (e) {
      commit('setErrorMessage', { message: e })
    } finally {
      commit('setLoading', { type, v: false})
    }
  },
  /** カンパニーデータを新規登録します */
  async addCompany ({ commit }, {data}) {
    const type = 'add'
    commit('setLoading', { type, v: true })
    try {
      // debugger;
      await djangoApi.postCompany(JSON.stringify(data))
      commit('setCompany', {id: state.company_id.slice(-1)[0] + 1, company: data['name']})
      
    } catch (e) {
      commit('setErrorMessage', { message: e })
    } finally {
      commit('setLoading', { type, v: false })
    }
  },
  /** 営業担当者名データを新規登録します */
  async addSalesName ({ commit }, {data}) {
    const type = 'add'
    commit('setLoading', { type, v: true })
    try {
      await djangoApi.postSalesName(JSON.stringify(data))
      commit('setSalesName', {id: state.salesName_id.slice(-1)[0] + 1, salesName: data['name']})
      
    } catch (e) {
      commit('setErrorMessage', { message: e })
    } finally {
      commit('setLoading', { type, v: false })
    }
  },
  /** タスクカテゴリーデータを新規登録します */
  async addTaskCategory ({ commit }, {data}) {
    const type = 'add'
    commit('setLoading', { type, v: true })
    try {
      await djangoApi.postTaskCategory(JSON.stringify(data))
      commit('setTaskCategory', {id: state.taskCategory_id.slice(-1)[0] + 1, company: data['task_category']})
      
    } catch (e) {
      commit('setErrorMessage', { message: e })
    } finally {
      commit('setLoading', { type, v: false })
    }
  },
  /** 日報更新 */
  async updateBrData ({ commit }, { reports }) {
    const type = 'update'
    commit('setLoading', {type, v: true})
    var item = {
      "report_id": reports['report_id'],
      "date": reports['date'],
      "company": reports['company'],
      "name": reports['name'],
      "target": reports['target'],
      "bugetPQ": reports['bugetPQ'],
      "bugetMQ": reports['bugetMQ'],
      "resultPQ": reports['resultPQ'],
      "resultMQ": reports['resultMQ'],
      "managercheck": reports['managercheck'],
      "seniorcheck": reports['seniorcheck'],
      "managercomment": reports['managercomment'],
      "seniorcomment": reports['seniorcomment'],
      "taskNo": reports['taskNo'],
      "inputComplete": reports['inputComplete']
    }
    try {
      await djangoApi.putReport(item.report_id, JSON.stringify(item))
      for (var i = 0; i < reports['taskNo']; i++) {
        item = {
          "task_id": reports['report_id'] + (i+1),
          "report_id": reports['report_id'],
          "taskNo": i + 1,
          "taskTitle": reports['taskTitles'][i],
          "destinations": reports['destinations'][i],
          "taskDetail": reports['taskDetails'][i],
          "taskStartTime": reports['taskStartTimes'][i],
          "taskEndTime": reports['taskEndTimes'][i]
        }
        // console.log({item})
        try {
          // var task_exist = await djangoApi.task(item.task_id)
          await djangoApi.task(item.task_id)
          // console.log('Exist task: ' + { task_exist });
          await djangoApi.putTask(item.task_id, JSON.stringify(item))
        } catch(e) {
          await djangoApi.postTask(JSON.stringify(item))
        }
      }
      commit('updateBrData', { report: reports })
    } catch (e) {
      commit('setErrorMessage', { message: e })
    } finally {
      commit('setLoading', { type, v: false})
    }
  },
  /** カンパニーデータを削除します */
  async deleteCompany ({ commit }, {id}) {
    const type = 'delete'
    commit('setLoading', { type, v: true })
    try {
      await djangoApi.deleteCompany(JSON.stringify(id))
    } catch (e) {
      commit('setErrorMessage', { message: e })
    } finally {
      commit('setLoading', { type, v: false })
    }
  },
  /** 営業担当者データを削除します */
  async deleteSalesName ({ commit }, {id}) {
    const type = 'delete'
    commit('setLoading', { type, v: true })
    try {
      await djangoApi.deleteSalesName(JSON.stringify(id))
    } catch (e) {
      commit('setErrorMessage', { message: e })
    } finally {
      commit('setLoading', { type, v: false })
    }
  },
  /** タスクカテゴリーデータを削除します */
  async deleteTaskCategory ({ commit }, {id}) {
    const type = 'delete'
    commit('setLoading', { type, v: true })
    try {
      await djangoApi.deleteTaskCategory(JSON.stringify(id))
    } catch (e) {
      commit('setErrorMessage', { message: e })
    } finally {
      commit('setLoading', { type, v: false })
    }
  },
  /** 日報一覧をテーブルに代入 */
  getBrTable ({commit}, {list}) {
    commit('getBrTable', {list: list})
  },
  /** ロールを設定します */
  saveRole ({commit}, {role}) {
    commit('saveRole', { role })
  },
  /** 認証結果を設定します */
  async saveAuth ({commit}, {data}) {
    const type = 'add'
    commit('setLoading', { type, v: true })
    commit('removeAuth')
    try {
      // debugger;
      const accessAuth = await djangoAuth.createJWT(data)
      commit('saveAuth', {auth: accessAuth.data})
    } catch (e) {
      commit('setErrorMessage', { message: e })
    } finally {
      commit('setLoading', { type, v: false })
    }
  },
  logout ({commit}) {
    commit('removeAuth')
  },
  /** 追加・編集フラグを設定します */
  saveActionType ({commit}, {actionType}) {
    commit('saveActionType', { actionType })
  },
  /** 日報を保存します */
  saveReports ({ commit }, { reports }) {
    commit('saveReports', { reports })
  },
  /** 日報を完了フラグつけて保存します */
  completeReports ({ commit }, { reports }) {
    commit('completeReports', { reports })
  },
  async resetReport ({ commit }) {
    commit('resetReport')
  },
  /** 設定を読み込みます */
  loadSettings ({ commit }) {
    commit('loadReports')
  },

  /** タスクを追加します */
  taskAdd ({commit}, {count}) {
    commit('taskAdd', {count})
  },
  /** タスクを削除します */
  taskDelete ({commit}, {count}) {
    commit('taskDelete', {count})
  },
  /** タスクタイトル変更 */
  editTaskTitle ({commit}, {count, title}) {
    commit('editTaskTitle', {count, title})
  },
  /** 訪問先変更 */
  editDestination ({commit}, {count, destination}) {
    commit('editDestination', {count, destination})
  },
  /** タスク詳細変更 */
  editTaskDetail ({commit}, {count, detail}) {
    commit('editTaskDetail', {count, detail})
  },
  /** タスク開始時間変更 */
  editTaskStartTime ({commit}, {count, startTime}) {
    commit('editTaskStartTime', {count, startTime})
  },
  /** タスク終了時間変更 */
  editTaskEndTime ({commit}, {count, endTime}) {
    commit('editTaskEndTime', {count, endTime})
  },
  resetPDFBrData ({commit}) {
    commit('resetPDFBrData');
  }
  
}

/**
 * Getters
 * 画面から取得され、Stateを加工して渡します
 */
const getters = {
  /** タスクの個数を渡す */
  featchCount (state) {
    return state.count.slice(-1)[0]
  },
  /** BRTableを渡す */
  featchBrTable (state) {
    return state.brTable
  }
}

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})

export default store