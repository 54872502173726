import axios from 'axios'

// 共通のヘッダーを設定したaxiosのインスタンス作成
// const drfUrl = 'http://127.0.0.1:8000/api'
const drfUrl = 'https://sales-report.kasyu-kogyo.com/api/'
// const djangoApi = axios.create({
//  baseURL: 'http://127.0.0.1:8000/api/',
//  headers: { 'content-type': 'application/json' }
// })
// const djangoAuth = axios.create({
//  baseURL: 'http://127.0.0.1:8000/authen/jwt/',
//  headers: {'content-type': 'application/json'}
// })

const djangoApi = axios.create({
    //baseURL: 'https://34.84.174.38:80/api/',// デプロイ先のIP
    baseURL: 'https://sales-report.kasyu-kogyo.com/api/',// デプロイ先のIP
    headers: { 'content-type': 'application/json' }
})

const djangoAuth = axios.create({
    //baseURL: 'https://34.84.174.38:80/api/',// デプロイ先のIP
    baseURL: 'https://sales-report.kasyu-kogyo.com/authen/jwt/',// デプロイ先のIP
    headers: { 'content-type': 'application/json' }
})
// response共通処理
// errorが含まれていたらrejectする
djangoApi.interceptors.response.use(res => {
  if (res.data.error) {
    return Promise.reject(res.data.error)
  }
  return Promise.resolve(res)
}, err => {
  return Promise.reject(err)
})

djangoAuth.interceptors.response.use(res => {
  if (res.data.error) {
    return Promise.reject(res.data.error)
  }
  return Promise.resolve(res)
}, err => {
  return Promise.reject(err)
})


/**
 * 日報リストを取得します。
 * @param {String} ()
 * @returns {Promise} {
                        "report_id": "aaa",
                        "date": "2020-11-25",
                        "company": "本社",
                        "name": "test",
                        "managercheck": false,
                        "seniorcheck": false,
                        "inputComplete": false
                      }
 */

const reportList = () => {
  return axios.get(
    `${drfUrl}/reportList/`,
    {
      headers: {
        Authorization: `JWT ${localStorage.JWT}`
      }
    }
  )
}


/**
 * 日報を取得します。
 * @param {String} (ulid)
 * @returns {Promise} {
                        "report_id": "aaa",
                        "date": "2020-11-25",
                        "company": "本社",
                        "name": "test",
                        "target": "頑張る",
                        "bugetPQ": 10000,
                        "bugetMQ": null,
                        "resultPQ": 9748,
                        "resultMQ": null,
                        "managercheck": false,
                        "seniorcheck": false,
                        "managercomment": "",
                        "seniorcomment": "",
                        "taskNo": 2,
                        "inputComplete": false
                      }
 */

const report = (ulid) => {
  // return djangoApi.get(`/report/${ulid}/`)
  return axios.get(
    `${drfUrl}/report/${ulid}/`,
    {
      headers: {
        Authorization: `JWT ${localStorage.JWT}`
      }
    }
  )
}

/**
 * 全日報データを取得を取得します。
 * @param {String} (ulid)
 * @returns {Promise} {
                        "report_id": "aaa",
                        "date": "2020-11-25",
                        "company": "本社",
                        "name": "test",
                        "target": "頑張る",
                        "bugetPQ": 10000,
                        "bugetMQ": null,
                        "resultPQ": 9748,
                        "resultMQ": null,
                        "managercheck": false,
                        "seniorcheck": false,
                        "managercomment": "",
                        "seniorcomment": "",
                        "taskNo": 2,
                        "inputComplete": false
                      }
 */

const reportAll = () => {
  // return djangoApi.get(`/report/`)
  return axios.get(
    `${drfUrl}/report/`,
    {
      headers: {
        Authorization: `JWT ${localStorage.JWT}`
      }
    }
  )
}

/**
 * タスク詳細を取得します。
 * @param {String} (taskId)
 * @returns {Promise} {
                        "task_id": "aaa1",
                        "report_id": "aaa",
                        "taskNo": 1,
                        "taskTitle": "Title1",
                        "desination": "Desination1",
                        "taskDetail": "Detail1",
                        "taskStartTime": "08:17:00",
                        "taskEndTime": "09:17:00"
                      }
 */
const task = taskId => {
  // return djangoApi.get(`/task/${taskId}/`)
  return axios.get(
    `${drfUrl}/task/${taskId}/`,
    {
      headers: {
        Authorization: `JWT ${localStorage.JWT}`
      }
    }
  )
}

/**
 * タスク詳細を取得します。
 * @param {String} (taskId)
 * @returns {Promise} {
                        "task_id": "aaa1",
                        "report_id": "aaa",
                        "taskNo": 1,
                        "taskTitle": "Title1",
                        "desination": "Desination1",
                        "taskDetail": "Detail1",
                        "taskStartTime": "08:17:00",
                        "taskEndTime": "09:17:00"
                      }
 */
const taskAll = () => {
  // return djangoApi.get(`/task/`)
  return axios.get(
    `${drfUrl}/task/`,
    {
      headers: {
        Authorization: `JWT ${localStorage.JWT}`
      }
    }
  )
}

/**
 * カンパニーリストを取得
 * @param {None}
 * @returns {Promise} {
 *                      "id": "aaaa",
 *                       "name": "本社"
 *                    }
 */
const company = () => {
  // return djangoApi.get(`/company/`)
  return axios.get(
    `${drfUrl}/company/`,
    {
      headers: {
        Authorization: `JWT ${localStorage.JWT}`
      }
    }
  )
}

/**
 * 営業担当者リストを取得
 * @param {None}
 * @returns {Promise} {
 *                      "company_id": "aaaa",
 *                      "id": "aaaa-1",
 *                       "name": "田中"
 *                    }
 */
const salesName = () => {
  // return djangoApi.get(`/salesname/`)
  return axios.get(
    `${drfUrl}/salesname/`,
    {
      headers: {
        Authorization: `JWT ${localStorage.JWT}`
      }
    }
  )
}

/**
 * 業務内容リストを取得
 * @param {None}
 * @returns {Promise} {
 *                      "auto_increment_id": 1
 *                      "task_category": "訪問"
 *                    }
 */
const taskCategory = () => {
  // return djangoApi.get(`/taskcategory/`)
  return axios.get(
    `${drfUrl}/taskcategory/`,
    {
      headers: {
        Authorization: `JWT ${localStorage.JWT}`
      }
    }
  )
}

/**
 * 日報新規追加
 * @param {*} data
 */
const postReport = (data) => {
  // return djangoApi.post('/report/', data)
  // .then(function (response) {
  //   console.log(response);
  // })
  // .catch(function (error) {
  //   console.log(error);
  // });
  return axios.post(
    `${drfUrl}/report/`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.JWT}`
      }
    },
  ).then(function (response) {
    console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });
}

/**
 * タスク新規追加
 * @param {*} data
 */
const postTask = (data) => {
  // return djangoApi.post('/task/', data)
  // .then(function (response) {
  //   console.log(response);
  // })
  // .catch(function (error) {
  //   console.log(error);
  // });
  return axios.post(
    `${drfUrl}/task/`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.JWT}`
      }
    },
  ).then(function (response) {
    console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });
}


/**
 * カンパニー新規追加
 * @param {*} data
 */
const postCompany = (data) => {
  // return djangoApi.post(`/company/`, data)
  // .then(function (response) {
  //   console.log(response);
  // })
  // .catch(function (error) {
  //   console.log(error);
  // });
  return axios.post(
    `${drfUrl}/company/`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.JWT}`
      }
    },
  ).then(function (response) {
    console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });
}

/**
 * 営業担当者名新規追加
 * @param {*} data
 */
const postSalesName = (data) => {
  // return djangoApi.post(`/salesname/`, data)
  // .then(function (response) {
  //   console.log(response);
  // })
  // .catch(function (error) {
  //   console.log(error);
  // });
  return axios.post(
    `${drfUrl}/salesname/`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.JWT}`
      }
    },
  ).then(function (response) {
    console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });
}

/**
 * タスクカテゴリー新規追加
 * @param {*} data
 */
const postTaskCategory = (data) => {
  // return djangoApi.post(`/taskcategory/`, data)
  // .then(function (response) {
  //   console.log(response);
  // })
  // .catch(function (error) {
  //   console.log(error);
  // });
  return axios.post(
    `${drfUrl}/taskcategory/`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.JWT}`
      }
    },
  ).then(function (response) {
    console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });
}

/**
 * jwt取得
 * @param {String} 'username', 'password'
 */
const createJWT = (data) => {
  // debugger;
  // console.log({username: username})
  // console.log({password: password})
  const res = djangoAuth.post(`/create`, data)
  // res.then(function (response) {
  //   console.log(response);
  // })
  // .catch(function (error) {
  //   console.log(error);
  // });
  // // console.log({State: res.Promise})
  // console.log(res)
  return res
}

/**
 * 日報編集
 * @param {*} data
 */
const putReport = (id, data) => {
  // return djangoApi.put(`/report/${id}/`, data)
  // .then(function (response) {
  //   console.log(response);
  // })
  // .catch(function (error) {
  //   console.log(error);
  // });
  return axios.put(
    `${drfUrl}/report/${id}/`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.JWT}`
      }
    },
  ).then(function (response) {
    console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });
}
/**
 * タスク編集
 * @param {*} data
 */
const putTask = (id, data) => {
  // return djangoApi.put(`/task/${id}/`, data)
  // .then(function (response) {
  //   console.log(response);
  // })
  // .catch(function (error) {
  //   console.log(error);
  // });
  return axios.put(
    `${drfUrl}/task/${id}/`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.JWT}`
      }
    },
  ).then(function (response) {
    console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });
}
/**
 * タスク削除
 * @param {*} data
 */
const deleteTask = (id) => {
  // console.log(id)
  // return djangoApi.delete(`/task/${id}/`)
  // .then(function (response) {
  //   console.log(response);
  // })
  // .catch(function (error) {
  //   console.log(error);
  // });
  return axios.delete(
    `${drfUrl}/task/${id}/`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.JWT}`
      }
    },
  ).then(function (response) {
    console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });
}

/** カンパニー削除
* @param {*} data
*/
const deleteCompany = (id) => {
  // return djangoApi.delete(`/company/${id}/`)
  // .then(function (response) {
  //   console.log(response);
  // })
  // .catch(function (error) {
  //   console.log(error);
  // });
  return axios.delete(
    `${drfUrl}/company/${id}/`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.JWT}`
      }
    },
  ).then(function (response) {
    console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });
 }

 /** 営業担当者削除
* @param {*} data
*/
const deleteSalesName = (id) => {
  // return djangoApi.delete(`/salesname/${id}/`)
  // .then(function (response) {
  //   console.log(response);
  // })
  // .catch(function (error) {
  //   console.log(error);
  // });
  return axios.delete(
    `${drfUrl}/salesname/${id}/`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.JWT}`
      }
    },
  ).then(function (response) {
    console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });
 }

 /** タスクカテゴリー削除
* @param {*} data
*/
const deleteTaskCategory = (id) => {
  // return djangoApi.delete(`/taskcategory/${id}/`)
  // .then(function (response) {
  //   console.log(response);
  // })
  // .catch(function (error) {
  //   console.log(error);
  // });
  return axios.delete(
    `${drfUrl}/taskcategory/${id}/`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.JWT}`
      }
    },
  ).then(function (response) {
    console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });
 }

export default {
  reportList,
  report,
  reportAll,
  task,
  company,
  salesName,
  taskCategory,
  taskAll,
  postReport,
  postTask,
  postCompany,
  postSalesName,
  postTaskCategory,
  createJWT,
  putReport,
  putTask,
  deleteTask,
  deleteCompany,
  deleteSalesName,
  deleteTaskCategory,
}
