<template>
  <v-dialog v-model="show" max-width="2000px">
    <v-card>
      <v-card-title>{{ titleText }}</v-card-title>
      <v-divider />
      <v-card-text>
        <v-container>
          <v-row justify="center" align-content="center">
            <v-col cols="12" md="6">
              <v-row justify="center" align-content="center">
                <v-col cols="12" md="12">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="reports.date"
                    v-bind:disabled="actionType !== 'add'"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="reports.date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        hide-details
                      />
                    </template>
                    <v-date-picker
                      v-model="reports.date"
                      type="date"
                      color="red"
                      locale="ja-jp"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn text color="grey" @click="menu = false"
                        >キャンセル</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(reports.date)"
                        >選択</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row justify="center" align-content="center">
                <v-col class="d-flex" cols="12" sm="6">
                  <v-select
                    v-model="reports.company_select"
                    :items="company"
                    v-bind:disabled="actionType !== 'add'"
                    label="所属"
                    solo
                  ></v-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-select
                    v-model="reports.name_select"
                    :items="name"
                    v-bind:disabled="actionType !== 'add'"
                    label="名前"
                    solo
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="3">
              <v-container fluid>
                <v-card color="(0,0,0,1)">
                  <v-checkbox
                    v-model="reports.managercheck"
                    label="支配人"
                    v-bind:disabled="role !== 'manage'"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="reports.seniorcheck"
                    label="上長"
                    v-bind:disabled="role !== 'senior'"
                  ></v-checkbox>
                </v-card>
              </v-container>
            </v-col>
          </v-row>
          <v-row justify="center" align-content="center">
            <v-col class="d-flex" cols="12" sm="6">
              <v-container>
                <v-row justify="center" align-conten="center">
                  <v-col class="d-flex" cols="12" sm="12">
                    <v-textarea
                      v-model="reports.target"
                      label="行動と対策"
                      filled
                      v-bind:disabled="role !== 'sales'"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row justify="center" align-content="center">
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="reports.bugetPQ"
                      label="予算PQ"
                      filled
                      v-bind:disabled="role !== 'sales'"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="reports.bugetMQ"
                      label="予算MQ"
                      filled
                      v-bind:disabled="role !== 'sales'"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center" align-content="center">
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="reports.resultPQ"
                      label="実績PQ"
                      filled
                      v-bind:disabled="role !== 'sales'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="reports.resultMQ"
                      label="実績MQ"
                      filled
                      v-bind:disabled="role !== 'sales'"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center" align-content="center">
                  <v-col cols="12" sm="6">
                    <p>PQ予実差</p>
                    <v-card class="pa-2" outlined tile>
                      {{ factPQ }}
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <p>MQ予実差</p>
                    <v-card class="pa-2" outlined tile>
                      {{ factMQ }}
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col class="d-flex" cols="12" sm="3">
              <v-container>
                <v-row justify="center" align-content="center">
                  <v-col cols="12" sm="12">
                    <v-textarea
                      v-model="reports.managercomment"
                      label="支配人コメント"
                      filled
                      v-bind:disabled="role !== 'manage'"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row justify="center" align-content="center">
                  <v-col cols="12" sm="12">
                    <v-textarea
                      v-model="reports.seniorcomment"
                      label="上長コメント"
                      filled
                      v-bind:disabled="role !== 'senior'"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <v-container>
            <taskAddDelete ref="taskAddDelete" />
          </v-container>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue" text :disabled="loading" @click="onClickClose">
          キャンセル
        </v-btn>
        <v-btn
          color="blue"
          text
          :disabled="loading || role !== 'sales'"
          @click="onClickSave"
        >
          一時保存
        </v-btn>
        <v-btn color="blue" text :disabled="loading" @click="onClickComplete">
          提出済み
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import taskAddDelete from "../components/taskAddDelete.vue";
import store from "../store/index.js";
import { mapState, mapActions } from "vuex";

export default {
  name: "reports-table",
  components: {
    taskAddDelete,
  },
  data() {
    const today = new Date();
    const year = today.getFullYear();
    const month = ("0" + (today.getMonth() + 1)).slice(-2);
    const date = ("0" + today.getDate()).slice(-2);

    return {
      show: false,
      menu: false,
      editedIndex: -1,
      // actionType: 'add',
      report: [],
      headers: [
        {
          text: "日付",
          align: "start",
          sortable: true,
          value: "date",
        },
        { text: "カンパニー", value: "company" },
        { text: "名前", value: "name" },
        { text: "支配人チェック", value: "managercheck" },
        { text: "上長チェック", value: "seniorcheck" },
        { text: "記入済み", value: "inputComplete" },
        { text: "編集", value: "actions", sortable: false },
      ],
      reports: {
        report_id: "",
        date: `${year}-${month}-${date}`,
        company_select: "",
        name_select: "",
        target: "",
        bugetPQ: 0,
        bugetMQ: 0,
        resultPQ: 0,
        resultMQ: 0,
        managercheck: false,
        seniorcheck: false,
        managercomment: "",
        seniorcomment: "",
        taskNo: 1,
        taskStartTimes: [],
        taskEndTimes: [],
        taskTitles: [],
        destinations: [],
        taskDetails: [],
        inputComplete: false,
      },
      taskNoFeatch: [],
      factPQ: 0,
      factMQ: 0,
      company: "",
      name: "",
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    "reports.bugetPQ": function(now) {
      this.factPQ = this.reports.resultPQ - now;
    },
    "reports.bugetMQ": function(now) {
      this.factMQ = this.reports.resultMQ - now;
    },
    "reports.resultPQ": function(now) {
      this.factPQ = now - this.reports.bugetPQ;
    },
    "reports.resultMQ": function(now) {
      this.factMQ = now - this.reports.bugetMQ;
    },
    "reports.date": function(now) {
      if (this.actionType === "add") {
        this.reports.report_id = this.ulid(
          now,
          this.reports.company_select,
          this.reports.name_select
        );
      }
    },
    "reports.company_select": function(now) {
      if (this.actionType === "add") {
        this.reports.report_id = this.ulid(
          this.reports.date,
          now,
          this.reports.name_select
        );
      }
    },
    "reports.name_select": function(now) {
      if (this.actionType === "add") {
        this.reports.report_id = this.ulid(
          this.reports.date,
          this.reports.company_select,
          now
        );
      }
    },
  },
  computed: {
    /** ダイアログのタイトル */
    titleText() {
      return this.actionType === "add" ? "日報追加" : "日報確認・編集";
    },
    ...mapState({
      /** 指定日報読み込み */
      brData: (state) => state.brData,
      /** ローディング状態 */
      loading: (state) => state.loading.add,
      /** 追加・編集フラグ取得 */
      actionType: (state) => state.actionType,
      /** stateのreortsを取得する */
      stateReports: (state) => state.reports,
      /** role取得 */
      role: (state) => state.role,
      /** company */
      company_set: (state) => state.company,
      /** salesName */
      name_set: (state) => state.salesName,
    }),
  },
  methods: {
    ...mapActions([
      /** 日報データ追加 */
      "addBrData",
      /** 日報データ更新 */
      "updateBrData",
      /** 日報リセット */
      "resetReport",
      /** 設定データ取得 */
      "fetchCompany",
      "fetchSalesName",
    ]),
    async loadSettingData() {
      await this.fetchCompany();
      await this.fetchSalesName();
    },
    /** ダイアログを開く */
    async open(actionType) {
      this.show = true;
      await this.resetForm();
      if (actionType === "edit") {
        this.reports.report_id = this.brData.report_id;
        this.reports.date = this.brData.date;
        this.reports.company_select = this.brData.company;
        this.reports.name_select = this.brData.name;
        this.reports.managercheck = this.brData.managercheck;
        this.reports.seniorcheck = this.brData.seniorcheck;
        this.reports.managercomment = this.brData.managercomment;
        this.reports.seniorcomment = this.brData.seniorcomment;
        this.reports.target = this.brData.target;
        this.reports.bugetPQ = this.brData.bugetPQ;
        this.reports.bugetMQ = this.brData.bugetMQ;
        this.reports.resultPQ = this.brData.resultPQ;
        this.reports.resultMQ = this.brData.resultMQ;
        this.reports.taskNo = this.brData.taskNo;
        this.reports.taskTitles = this.brData.taskTitles;
        this.reports.destinations = this.brData.destinations;
        this.reports.taskDetails = this.brData.taskDetails;
        this.reports.taskStartTimes = this.brData.taskStartTime;
        this.reports.taskEndTimes = this.brData.taskEndTime;
      }
      await store.dispatch("saveActionType", { actionType: actionType });
      this.$refs.taskAddDelete.load(this.reports.taskNo);
    },
    /** キャンセルがクリックされたとき */
    onClickClose() {
      this.show = false;
    },
    onClickAction() {},
    async onClickSave() {
      this.reports.taskNo = this.stateReports.taskNo;
      await this.$store.dispatch("saveReports", { reports: this.reports });
      if (this.actionType === "add") {
        await this.addBrData({ reports: this.$store.state.reports });
      } else {
        // console.log({reports: this.$store.state.reports})
        await this.updateBrData({ reports: this.$store.state.reports });
      }
      this.onClickClose();
    },
    async onClickComplete() {
      this.reports.taskNo = this.stateReports.taskNo;
      this.$store.dispatch("completeReports", { reports: this.reports });
      if (this.actionType === "add") {
        await this.addBrData({ reports: this.$store.state.reports });
      } else {
        await this.updateBrData({ reports: this.$store.state.reports });
      }
      this.onClickClose();
    },
    /** フォームの内容を初期化します */
    async resetForm() {
      const today = new Date();
      const year = today.getFullYear();
      const month = ("0" + (today.getMonth() + 1)).slice(-2);
      const date = ("0" + today.getDate()).slice(-2);
      this.reports.date = `${year}-${month}-${date}`;
      // this.company = ['本社', '第一カンパニー', '第二カンパニー', 'WJカンパニー', 'エンジニアリングカンパニー'];
      // this.name = ['佐﨑','大脇','高森','永田', '高井良', '磯辺','江口','池上'];
      this.reports = {
        report_id: "",
        date: `${year}-${month}-${date}`,
        company_select: "",
        name_select: "",
        target: "",
        bugetPQ: 0,
        bugetMQ: 0,
        resultPQ: 0,
        resultMQ: 0,
        managercheck: false,
        seniorcheck: false,
        managercomment: "",
        seniorcomment: "",
        taskNo: 1,
        taskStartTimes: [],
        taskEndTimes: [],
        taskTitles: [],
        destinations: [],
        taskDetails: [],
        inputComplete: false,
      };
      this.factPQ = "";
      this.factMQ = "";
    },
    /** ulidを生成します */
    ulid(date, company, name) {
      function date2sec(date) {
        var d = new Date(date);
        return d.getTime();
      }
      // function company2spell(company_sel, company) {
      // 	// const company_list = ['本社', '第一カンパニー', '第二カンパニー', 'WJカンパニー', 'エンジニアリングカンパニー'];
      // 	const company_pos = company.indexOf(company_sel);
      // 	const companySpell_list = ['head office', 'first company', 'second company', 'wj company', 'engineering company'];
      // 	return companySpell_list[company_pos]
      // }
      // function name2spell(name, name_sel) {
      // 	// const name_list = ['佐﨑','大脇','高森','永田', '高井良', '磯辺','永嘉','江口','池上'];
      // 	const name_pos = name.indexOf(name_sel);
      // 	const nameSpell_list = ['sasaki', 'oowaki', 'takamori', 'nagata', 'takaira', 'isobe', 'eika', 'eguchi', 'ikegami'];
      // 	return nameSpell_list[name_pos]
      // }

      // ulid準拠でTimeエンコード
      function encodeTime(time, len = 10) {
        const ENCODING = "0123456789ABCDEFGHJKMNPQRSTVWXYZ"; // Crockford's Base32
        const ENCODING_LEN = ENCODING.length;

        if (isNaN(time)) {
          throw new Error(time + " must be a number");
        }
        if (Number.isInteger(time) === false) {
          time = time - 0;
        }

        let mod;
        let str = "";
        for (; len > 0; len--) {
          mod = time % ENCODING_LEN;
          str = ENCODING.charAt(mod) + str;
          time = (time - mod) / ENCODING_LEN;
        }
        return str;
      }

      // 疑似ulid生成
      const sec = date2sec(date);
      const timeEncoded = encodeTime(sec);
      // const companySpell = company2spell(this.company, company);
      // const nameSpell = name2spell(this.name, name);

      const hash1 = btoa(unescape(encodeURIComponent(company)));
      const hash2 = btoa(unescape(encodeURIComponent(name)));
      const ulid_ = timeEncoded + "_" + hash1 + "_" + hash2;
      const ulidFix = ulid_.replaceAll('/', '=-=');
      return ulidFix;
    },
  },
  async created() {
    await this.loadSettingData();
    this.company = this.company_set;
    this.name = this.name_set;
  },
};
</script>
