import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import reportTable from '../views/reportTable.vue'
import printout from '../views/printout.vue'
import settings from '../views/settings.vue'
import store from '../store/index.js'

// import { mapState, mapActions } from 'vuex'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      isPublic: false
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/report-table/:role',
    name: 'reportTable',
    component: reportTable,
    beforeEach(to, from, next) {
      this.store.dispatch('saveRole', {role: this.router.params.role});
      next();
    },
  },
  {
    path: '/printout',
    name: 'printout',
    component: printout,
    beforeEach(to, from, next) {
      this.store.dispatch('saveRole', {role: this.router.params.role});
      next();
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: settings,
    beforeEach(to, from, next) {
      this.store.dispatch('saveRole', {role: this.router.params.role});
      next();
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // ToDo: 処理の形がイケてないため時間があったらリファクタリング
  // console.log({Auth: store.state.auth})
  if (to.matched.some(page => page.meta.isPublic) || store.state.auth!=="") {
    next()
  } else {
    next('/login')
  }

  if (to.path == '/report-table/sales') {
    store.dispatch('saveRole', {role: 'sales'});
  }
  else if (to.path == '/report-table/manage') {
    store.dispatch('saveRole', {role: 'manage'});
  }
  else if (to.path == '/report-table/senior') {
    store.dispatch('saveRole', {role: 'senior'});
  }
  else{
    store.dispatch('saveRole', {role: 'other'});
  }
  next();
})
export default router
