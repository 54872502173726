<template>
    <v-container>
        <div v-for="(c, key) in count" :key="key">
            <taskList ref='taskList' :c='c'></taskList>
        </div>
        <v-btn v-on:click='addTask'  v-if="role=='sales'">Add</v-btn>
        <v-btn v-if='count.slice(-1)[0] > 0 && role=="sales"' v-on:click='deleteTask'>Delete</v-btn>
        <p v-if="role=='sales'">{{ count.slice(-1)[0] + 1 }} times.</p>
    </v-container>
</template>

<script>
import taskList from '../components/taskList.vue'
import { mapState } from 'vuex'

export default {
    name: 'add-delete-task',
    components: {
        taskList
    },
    data: function () {
        return {
            count: [0]
        }
    },
    computed: {
        ...mapState({
            /** 指定日報のタスク数取得 */
						brData: state => state.brData,
						/** 追加・編集フラグ */
						actionType: state => state.actionType,
						/** role取得 */
						role: state => state.role,
        })
    },
    methods: {
        async load(taskNo) {
						this.count = [0]
						if (this.actionType === 'edit') {
							for (var i = 1; i < taskNo; i++) {
								await this.count.push(i)
							}
						}
        },
        addTask: function() {
					this.count.push(this.count.slice(-1)[0] + 1);
					this.$store.dispatch('taskAdd', { count: this.count});
        },
        deleteTask: function() {
					this.count.pop();
					this.$store.dispatch('taskDelete', { count: this.count});
        },
        
    }
}
</script>