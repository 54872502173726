<template>
  <div>
    <div class="sheet">
      <div class="header">
        <h1>営業日報</h1>
        <table class="contentTable">
          <tr>
            <th class="contentTh">記入日</th>
            <td class="contentTd">{{ date }}</td>
          </tr>
          <tr>
            <th class="contentTh">所属</th>
            <td class="contentTd">{{ company }}</td>
          </tr>
          <tr>
            <th class="contentTh">営業担当者</th>
            <td class="contentName">{{ name }}</td>
          </tr>
        </table>

        <table class="checkTable">
          <tr>
            <th class="checkTh">支配人チェック</th>
            <td class="checkTd">
              <input type="checkbox" checked="checked" />
            </td>
          </tr>
          <tr>
            <th class="checkTh">上長チェック</th>
            <td class="checkTd"><input type="checkbox" checked="checked" /></td>
          </tr>
        </table>
      </div>
      <div class="target">
        <div class="actionSet">
          <label class="targetLabel">行動と対策</label>
          <br />
          <textarea
            class="action"
            v-model="target"
            ref="heightTarget"
            :style="stylesTarget"
          >
          </textarea>
        </div>
        <div>
          <div class="PQMQ">
            <label class="PQMQState">予実状況</label>
            <label class="PQMQUnit">[単位：千円]</label>
          </div>
          <table class="PQMQTable">
            <tr>
              <th class="PQMQLabel" />
              <th class="PQMQTh">PQ</th>
              <th class="PQMQTh">MQ</th>
            </tr>
            <tr>
              <td class="PQMQLabel">予算</td>
              <td class="PQMQTd">{{ bugetPQ }}</td>
              <td class="PQMQTd">{{ bugetMQ }}</td>
            </tr>
            <tr>
              <td class="PQMQLabel">実績</td>
              <td class="PQMQTd">{{ resultPQ }}</td>
              <td class="PQMQTd">{{ resultMQ }}</td>
            </tr>
            <tr>
              <td class="PQMQLabel">差異</td>
              <td class="PQMQTd">{{ factPQ }}</td>
              <td class="PQMQTd">{{ factMQ }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="manageCommentGroup">
        <div class="manageCommentSet">
          <label>支配人コメント</label>
          <br />
          <textarea
            class="manageComment"
            v-model="managercomment"
            ref="heightManege"
            :style="stylesManege"
          >
          </textarea>
        </div>
        <div class="manageCommentSet">
          <label>上長コメント</label>
          <br />
          <textarea
            class="manageComment"
            v-model="seniorcomment"
            ref="heightSenior"
            :style="stylesSenior"
          >
          </textarea>
        </div>
      </div>
      <taskAddDelete ref="taskAddDelete" :reportNum="c" />
    </div>
  </div>
</template>

<script>
import taskAddDelete from "../components/taskAddDeletePDF.vue";
import { mapState } from "vuex";
import "../styles/printout.scss";

export default {
  name: "reports-table",
  components: {
    taskAddDelete,
  },
  data() {
    return {
      heightTarget: "10px",
      heightManege: "10px",
      heightSenior: "10px",
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    bugetPQ: function(now) {
      this.factPQ = this.resultPQ - now;
    },
    bugetMQ: function(now) {
      this.factMQ = this.resultMQ - now;
    },
    resultPQ: function(now) {
      this.factPQ = now - this.bugetPQ;
    },
    resultMQ: function(now) {
      this.factMQ = now - this.bugetMQ;
    },
    action() {
      this.resizeTarget();
      this.resizeManege();
      this.resizeSenior();
    },
  },
  computed: {
    ...mapState({
      /** ローディング状態 */
      loading: (state) => state.loading.add,
      /** role取得 */
      role: (state) => state.role,
      /** PDF用データ取得 */
      brDataPDF: (state) => state.brDataPDF,
    }),
    stylesTarget() {
      return {
        height: this.heightTarget,
      };
    },
    stylesManege() {
      return {
        height: this.heightManege,
      };
    },
    stylesSenior() {
      return {
        height: this.heightSenior,
      };
    },
    date: {
      get() {
        try {
          // debugger;
          console.log({ date: this.jpDateFormat(this.brDataPDF[this.c].date) });
          console.log({ cntInItem: this.c });
          // return this.brDataPDF[this.c].date.replace(/-/g, "/");
          return this.jpDateFormat(this.brDataPDF[this.c].date);
        } catch (e) {
          return [""];
        }
      },
    },
    company: {
      get() {
        try {
          return this.brDataPDF[this.c].company;
        } catch (e) {
          return [""];
        }
      },
    },
    name: {
      get() {
        try {
          console.log({ name: this.brDataPDF[this.c].name });
          return this.brDataPDF[this.c].name;
        } catch (e) {
          return [""];
        }
      },
    },
    managercheck: {
      get() {
        try {
          return this.brDataPDF[this.c].managercheck;
        } catch (e) {
          return [""];
        }
      },
    },
    seniorcheck: {
      get() {
        try {
          return this.brDataPDF[this.c].seniorcheck;
        } catch (e) {
          return [""];
        }
      },
    },
    managercomment: {
      get() {
        try {
          return this.brDataPDF[this.c].managercomment;
        } catch (e) {
          return [""];
        }
      },
    },
    seniorcomment: {
      get() {
        try {
          return this.brDataPDF[this.c].seniorcomment;
        } catch (e) {
          return [""];
        }
      },
    },
    target: {
      get() {
        try {
          return this.brDataPDF[this.c].target;
        } catch (e) {
          return [""];
        }
      },
    },
    bugetPQ: {
      get() {
        try {
          return this.moneyFormatter(this.brDataPDF[this.c].bugetPQ).replace(
            "￥",
            ""
          );
        } catch (e) {
          return [""];
        }
      },
    },
    bugetMQ: {
      get() {
        try {
          return this.moneyFormatter(this.brDataPDF[this.c].bugetMQ).replace(
            "￥",
            ""
          );
        } catch (e) {
          return [""];
        }
      },
    },
    resultPQ: {
      get() {
        try {
          return this.moneyFormatter(this.brDataPDF[this.c].resultPQ).replace(
            "￥",
            ""
          );
        } catch (e) {
          return [""];
        }
      },
    },
    resultMQ: {
      get() {
        try {
          return this.moneyFormatter(this.brDataPDF[this.c].resultMQ).replace(
            "￥",
            ""
          );
        } catch (e) {
          return [""];
        }
      },
    },
    factPQ: {
      get() {
        try {
          const diff =
            this.brDataPDF[this.c].resultPQ - this.brDataPDF[this.c].bugetPQ;
          if (diff > 0) {
            return this.moneyFormatter(diff).replace("￥", "");
          } else {
            return "▲" + this.moneyFormatter(-1 * diff).replace("￥", "");
          }
        } catch (e) {
          return [""];
        }
      },
    },
    factMQ: {
      get() {
        try {
          const diff =
            this.brDataPDF[this.c].resultMQ - this.brDataPDF[this.c].bugetMQ;
          if (diff > 0) {
            return this.moneyFormatter(diff).replace("￥", "");
          } else {
            return "▲" + this.moneyFormatter(-1 * diff).replace("￥", "");
          }
        } catch (e) {
          return [""];
        }
      },
    },
    taskNo: {
      get() {
        try {
          return this.brDataPDF[this.c].taskNo;
        } catch (e) {
          return [""];
        }
      },
    },
  },
  methods: {
    resizeTarget: function() {
      this.heightTarget = "auto";
      this.$nextTick(() => {
        this.heightTarget = this.$refs.heightTarget.scrollHeight + "px";
      });
    },
    resizeManege: function() {
      this.heightManege = "auto";
      this.$nextTick(() => {
        this.heightManege = this.$refs.heightManege.scrollHeight + "px";
      });
    },
    resizeSenior: function() {
      this.heightSenior = "auto";
      this.$nextTick(() => {
        this.heightSenior = this.$refs.heightSenior.scrollHeight + "px";
      });
    },
    moneyFormatter: function(money) {
      const formatter = new Intl.NumberFormat("ja-JP", {
        style: "currency",
        currency: "JPY",
      });
      return formatter.format(money);
    },
    jpDateFormat: function(date) {
      date = new Date(date);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var dayOfWeek = date.getDay();
      var dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][dayOfWeek];
      return `${year}年${month}月${day}日(${dayOfWeekStr})`;
    },
  },
  mounted() {
    this.resizeTarget();
    this.resizeManege();
    this.resizeSenior();
  },
  props: ["c"],
};
</script>
