<template>
  <v-app>
    <div class="sheets">
      <!-- ツールバー -->
      <v-app-bar app color="red" dark>
        <!-- タイトル -->
        <v-toolbar-title>営業日報</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- アカウントアイコンのボタンロール変更画面に移る -->
        <v-btn icon to="/" v-show="showMenu==true">
          <v-icon>mdi-account-multiple</v-icon>
        </v-btn>
        <!-- pdfアイコンのボタンロール変更画面に移る -->
        <v-btn icon to="/printout" v-show="showMenu==true">
          <v-icon>mdi-file-pdf-box</v-icon>
        </v-btn>
        <!-- アイコンのボタンロール変更画面に移る -->
        <v-btn icon to="/settings" v-show="showMenu==true">
          <v-icon>mdi-database</v-icon>
        </v-btn>
        <!-- ログインボタン -->
        <v-btn icon to="/login" v-show="showMenu==false" @click="logout">
          <v-icon>mdi-login</v-icon>
        </v-btn>
        <!-- ログアウトボタン -->
        <v-btn icon to="/login" v-show="showMenu==true" @click="logout">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
        <!-- </router-link> -->
      </v-app-bar>
    </div>
    <!-- メインコンテンツ -->
    <v-main>
      <v-container fluid>
        <!-- router-view の中身がパスによって切り替わる -->
        <router-view></router-view>
      </v-container>
    </v-main>
    <!-- スナックバー -->
    <v-snackbar v-model="snackbar" color="error">{{ message }}</v-snackbar>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import "./styles/printout.scss";

export default {
  name: "App",
  data() {
    return {
      snackbar: false,
      message: '',
      showMenu: false,
    };
  },
  computed: mapState({
    errorMessage: (state) => state.errorMessage,
    auth: (state) => state.auth
  }),
  watch: {
    errorMessage() {
      this.snackbar = true;
      if (localStorage.JWT) {
        this.message = this.errorMessage
      }
      else {
        this.message = '正しいIDとパスワードを入力してください。'
      }
    },
    auth() {
      if (localStorage.JWT) {
        this.showMenu = true
      }
      else {
        this.showMenu = false
      }
    }
  },
  methods: {
    ...mapActions([
      "logout"
    ]),
    logout() {
      // debugger;
      this.showMenu = false;
      localStorage.removeItem("JWT");
      this.auth = '';
      location.reload();
      //this.logout()//.then(() => this.$router.push('/login'));

    }
  }
};
</script>
