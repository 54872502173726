<template>
  <div>
    <table class="taskTable">
      <tr>
        <td class="taskLabel">
          {{ `業務内容${c + 1}` }}
        </td>
        <td class="taskItem">
          {{ taskTitle }}
        </td>
        <td class="taskLabel">
          客先名
        </td>
        <td class="taskItem">
          {{ destination }}
        </td>
        <td class="taskLabelTime">
          開始
        </td>
        <td class="taskTime">
          {{ taskStartTime }}
        </td>
        <td class="taskLabelTime">
          終了
        </td>
        <td class="taskTime">
          {{ taskEndTime }}
        </td>
      </tr>
    </table>

    <textarea
      class="taskDetail"
      v-model="taskDetail"
      ref="height"
      :style="styles"
    >
    </textarea>
  </div>
</template>
<script>
import { mapState } from "vuex";
import "../styles/printout.scss";

export default {
  name: "taskList",
  data() {
    return {
      height: "16px",
    };
  },
  watch: {
    action() {
      this.resize();
    },
  },
  computed: {
    ...mapState({
      brData: (state) => state.brData,
      /** 追加・編集状態を取得 */
      actionType: (state) => state.actionType,
      /** role取得 */
      role: (state) => state.role,
      /** PDFデータ取得 */
      brDataPDF: (state) => state.brDataPDF,
    }),
    styles() {
      return {
        height: this.height,
      };
    },
    taskTitle: {
      get() {
        try {
          return this.brDataPDF[this.reportNum].taskTitles[this.c];
        } catch (e) {
          return [""];
        }
      },
    },
    destination: {
      get() {
        try {
          return this.brDataPDF[this.reportNum].destinations[this.c];
        } catch (e) {
          return [""];
        }
      },
    },
    taskDetail: {
      get() {
        try {
          return this.brDataPDF[this.reportNum].taskDetails[this.c];
        } catch (e) {
          return [""];
        }
      },
    },
    taskStartTime: {
      get() {
        try {
          const startTime = this.brDataPDF[this.reportNum].taskStartTimes[
            this.c
          ];
          return startTime.substr(0, 5);
        } catch (e) {
          return ["00:00"];
        }
      },
    },
    taskEndTime: {
      get() {
        try {
          const endTime = this.brDataPDF[this.reportNum].taskEndTimes[this.c];
          return endTime.substr(0, 5);
        } catch (e) {
          return ["00:00"];
        }
      },
      set(value) {
        this.$store.dispatch("editTaskEndTime", {
          count: this.c,
          endTime: value,
        });
      },
    },
  },
  methods: {
    resize: function() {
      this.height = "auto";
      this.$nextTick(() => {
        this.height = this.$refs.height.scrollHeight + "px";
      });
    },
  },
  mounted() {
    this.resize();
  },
  props: ["reportNum", "c"],
};
</script>
