<template>
  
  <v-card flat>
    <v-card-text>
      <v-container>
        <v-spacer></v-spacer>
        <v-row
          align="center"
          justify="space-around"
        >
          <router-link tag="button" :to="{ name: 'reportTable', params: {role: 'sales'}}" >
            <v-btn color="primary" x-large >
              営業担当者
            </v-btn>
          </router-link>
        </v-row>
        <v-spacer></v-spacer>
        <br/>
        <br/>
        <br/>
        <v-row
          align="center"
          justify="space-around"
        >
          <router-link tag="button"  :to="{ name: 'reportTable', params: {role: 'senior'}}">
            <v-btn color="error" x-large>
              上長
            </v-btn>
          </router-link>
        </v-row>
        <br/>
        <br/>
        <br/>
        <v-row
          align="center"
          justify="space-around"
        >
          <router-link tag="button"  :to="{ name: 'reportTable', params: {role: 'manage'}}">
            <v-btn color="error" x-large>
              支配人
            </v-btn>
          </router-link>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'Home',
}
</script>