<template>
  <div>
    <v-card>
      <v-row justify="center" align-content="center">
        <v-col cols="12" sm="11">
          <v-textarea
            v-model="company_now"
            label="カンパニー"
            filled
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row justify="center" align-content="center">
        <v-col cols="12" sm="11">
          <v-textarea
            v-model="salesName_now"
            label="営業担当者名"
            filled
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row justify="center" align-content="center">
        <v-col cols="12" sm="11">
          <v-textarea
            v-model="taskCategory_now"
            label="業務内容"
            filled
          ></v-textarea>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue" text :disabled="loading" @click="onClickCancel">
          キャンセル
        </v-btn>
        <v-btn color="blue" text :disabled="loading" @click="onClickRegister">
          登録
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "settings",
  data() {
    return {
      company_now: [],
      company_id_now: [],
      salesName_now: [],
      salesName_id_now: [],
      taskCategory_now: [],
      taskCategory_id_now: [],
    };
  },
  computed: {
    ...mapState({
      /** ローディング状態 */
      loading: (state) => state.loading.add,
      /** company */
      company: (state) => state.company,
      company_id: (state) => state.company_id,
      /** salesName */
      salesName: (state) => state.salesName,
      salesName_id: (state) => state.salesName_id,
      /** taskCategory */
      taskCategory: (state) => state.taskCategory,
      taskCategory_id: (state) => state.taskCategory_id,
    }),
  },
  methods: {
    ...mapActions([
      /** 設定データ取得 */
      "fetchCompany",
      "fetchSalesName",
      "fetchTaskCategory",
      /** 新規登録メソッド */
      "addCompany",
      "addSalesName",
      "addTaskCategory",
      /** 削除メソッド */
      "deleteCompany",
      "deleteSalesName",
      "deleteTaskCategory",
    ]),
    async loadSettingData() {
      await this.fetchCompany();
      await this.fetchSalesName();
      await this.fetchTaskCategory();
      // var a = this.company.toString();
      console.log(this.company.toString());
      this.company_now = this.company.toString().replace(/,/g, "\n");
      this.company_id_now = this.company_id;
      this.salesName_now = this.salesName.toString().replace(/,/g, "\n");
      this.salesName_id_now = this.salesName_id;
      this.taskCategory_now = this.taskCategory.toString().replace(/,/g, "\n");
      this.taskCategory_id_now = this.taskCategory_id;
    },
    onClickCancel() {
      this.loadSettingData();
    },
    async onClickRegister() {
      /** カンパニーの登録処理 */
      var newCompany = [];
      var deleteCompany_id = this.company_id_now;
      var companyLength = this.company_now.split("\n").length;
      for (var i = 0; i < companyLength; i++) {
        if (this.company.indexOf(this.company_now.split("\n")[i]) === -1) {
          newCompany.push(this.company_now.split("\n")[i]);
        } else {
          console.log(
            this.company_id_now[
              this.company.indexOf(this.company_now.split("\n")[i])
            ]
          );
          deleteCompany_id = deleteCompany_id.filter(
            (n) =>
              n !==
              this.company_id_now[
                this.company.indexOf(this.company_now.split("\n")[i])
              ]
          );
        }
      }

      for (i = 0; i < newCompany.length; i++) {
        console.log(newCompany[i]);
        const data = {
          auto_increment_id: this.company.length + i + 1,
          name: newCompany[i],
        };
        await this.addCompany({ data: data });
      }

      for (i = 0; i < deleteCompany_id.length; i++) {
        console.log(deleteCompany_id[i]);
        await this.deleteCompany({ id: deleteCompany_id[i] });
      }

      /** 営業担当者の登録処理 */
      var newSalesName = [];
      var deleteSalesName_id = this.salesName_id_now;
      var salesNameLength = this.salesName_now.split("\n").length;
      for (i = 0; i < salesNameLength; i++) {
        if (this.salesName.indexOf(this.salesName_now.split("\n")[i]) === -1) {
          newSalesName.push(this.salesName_now.split("\n")[i]);
        } else {
          console.log(
            this.salesName_id_now[
              this.salesName.indexOf(this.salesName_now.split("\n")[i])
            ]
          );
          deleteSalesName_id = deleteSalesName_id.filter(
            (n) =>
              n !==
              this.salesName_id_now[
                this.salesName.indexOf(this.salesName_now.split("\n")[i])
              ]
          );
        }
      }

      for (i = 0; i < newSalesName.length; i++) {
        console.log(newSalesName[i]);
        const data = {
          auto_increment_id: this.salesName.length + i + 1,
          name: newSalesName[i],
        };
        await this.addSalesName({ data: data });
      }

      for (i = 0; i < deleteSalesName_id.length; i++) {
        console.log(deleteSalesName_id[i]);
        await this.deleteSalesName({ id: deleteSalesName_id[i] });
      }

      /** タスクカテゴリーの登録処理 */
      var newTaskCategory = [];
      var deleteTaskCategory_id = this.taskCategory_id_now;
      var taskCategoryLength = this.taskCategory_now.split("\n").length;
      for (i = 0; i < taskCategoryLength; i++) {
        if (
          this.taskCategory.indexOf(this.taskCategory_now.split("\n")[i]) === -1
        ) {
          newTaskCategory.push(this.taskCategory_now.split("\n")[i]);
        } else {
          console.log(
            this.taskCategory_id_now[
              this.taskCategory.indexOf(this.taskCategory_now.split("\n")[i])
            ]
          );
          deleteTaskCategory_id = deleteTaskCategory_id.filter(
            (n) =>
              n !==
              this.taskCategory_id_now[
                this.taskCategory.indexOf(this.taskCategory_now.split("\n")[i])
              ]
          );
        }
      }

      for (i = 0; i < newTaskCategory.length; i++) {
        console.log(newTaskCategory[i]);
        const data = {
          auto_increment_id: this.taskCategory.length + i + 1,
          task_category: newTaskCategory[i],
        };
        await this.addTaskCategory({ data: data });
      }

      for (i = 0; i < deleteTaskCategory_id.length; i++) {
        console.log(deleteTaskCategory_id[i]);
        await this.deleteTaskCategory({ id: deleteTaskCategory_id[i] });
      }

      this.loadSettingData();
    },
  },
  created() {
    // debugger;
    this.loadSettingData();
  },
};
</script>
