<template>
    <v-container>
        <v-row justify="center" align-content="center" >
            <v-col class="d-flex" cols="12" sm="5">
                <v-text-field
                    v-model="taskStartTime"
                    label="開始時間"
                    filled
                    type="time"
                    v-bind:disabled='role!=="sales"'
                ></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="12" sm="2"/>
            <v-col class="d-flex" cols="12" sm="5">
                <v-text-field
                    v-model="taskEndTime"
                    label="終了時間"
                    filled
                    type="time"
                    v-bind:disabled='role!=="sales"'
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row justify="center" align-content="center">
            <v-col class="d-flex" cols="12" sm="12">
                <v-select
                    :items="taskCategory"
                    v-model="taskTitle"
                    label="業務内容"
                    outlined
                    v-bind:disabled='role!=="sales"'
                ></v-select>
            </v-col>
        </v-row>
        <v-row justify="center" align-content="center">
            <v-col class="d-flex" cols="12" sm="12">
                <v-text-field
                    v-model="destination"
                    label="訪問先"
                    filled
                    v-bind:disabled='role!=="sales"'
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row justify="center" align-content="center">
            <v-col class="d-flex" cols="12" sm="12">
                <v-textarea
                    v-model="taskDetail"
                    label="詳細"
                    filled
                    auto-grow='true'
                    v-bind:disabled='role!=="sales"'
                ></v-textarea>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapState, mapActions } from "vuex"

export default {
    name: 'taskList',
    computed: {
        ...mapState({
            brData: state => state.brData,
            /** 追加・編集状態を取得 */
            actionType: state => state.actionType,
            /** role取得 */
            role: state => state.role,
            /** 業務カテゴリ取得 */
            taskCategory: state => state.taskCategory
        }),
        taskTitle: {
            get() {
                try {
                    if (this.actionType === 'edit') {
                        console.log(this.brData.taskTitles[this.c])
                        return this.brData.taskTitles[this.c]
                    } else {
                        return ['']
                    }
                } catch(e) {
                    return ['']
                }
            },
            set(value) {
                this.$store.dispatch('editTaskTitle', {count: this.c, title: value})
            }
        },
        destination: {
            get() {
                try {
                    if (this.actionType === 'edit') {
                        return this.brData.destinations[this.c]
                    } else {
                        return ['']
                    }
                } catch(e) {
                    return ['']
                }
            },
            set(value) {
                this.$store.dispatch('editDestination', {count: this.c, destination: value})
            }
        },
        taskDetail: {
            get() {
                try {
                    if (this.actionType === 'edit') {
                        return this.brData.taskDetails[this.c]
                    } else {
                        return ['']
                    }
                } catch(e) {
                    return ['']
                }
            },
            set(value) {
                this.$store.dispatch('editTaskDetail', {count: this.c, detail: value})
            }
        },
        taskStartTime: {
            get() {
                try {
                    if (this.actionType === 'edit') {
                        const startTime = this.brData.taskStartTimes[this.c]
                        return startTime.substr(0,5)
                    } else {
                        return ['00:00']
                    }
                } catch(e) {
                    return ['00:00']
                }
            },
            set(value) {
                this.$store.dispatch('editTaskStartTime', {count: this.c, startTime: value})
            }
        },
        taskEndTime: {
            get() {
                try {
                    if (this.actionType === 'edit') {
                        const endTime = this.brData.taskEndTimes[this.c]
                        return endTime.substr(0, 5)
                    } else {
                        return ['00:00']
                    }
                } catch(e) {
                    return ['00:00']
                }
            },
            set(value) {
                this.$store.dispatch('editTaskEndTime', {count: this.c, endTime: value})
            }
        }
    },
    methods: {
        ...mapActions([
            /** 日報データ取得 */
            "fetchTaskCategory",
        ]),
        async setTaskCategory() {
            await this.fetchTaskCategory();
        }
    },
    created() {
        this.setTaskCategory();
        console.log({taskCategory: this.taskCategory})
    },
	props: ['c']
}
</script>